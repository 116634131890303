<template>
  <div class="wrapper">
    <div class="bg"></div>
    <div class="title-wrapper">
      <div class="title-content">
        <h1>Please select a Device</h1>
      </div>
      <div class="title-content" style="cursor: pointer;" @click="getDevices">
        <h1><b-icon icon="arrow-clockwise" :style="{ color: loading ? 'grey' : 'black' }"></b-icon></h1>
      </div>
    </div>
    <div v-for="d in devices" :key="d.id" class="device" @click="selectDevice(d)">
      <!-- img :src="getImage({})" alt="no image" class="device-image"-->
      <div class="bg"></div>
      <div class="device-title">
        <span>{{d.name}}</span>
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SelectDevices',
  data() {
    return {
      devices: [],
    };
  },
  components: {
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    getDevices() {
      this.axios.get('https://api.spotify.com/v1/me/player/devices', {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => this.devices = res.data.devices);
    },
    selectDevice(d) {
      this.$store.commit('game/SET_DEVICE', d.id);
      this.$router.push('/players');
    },
  }
}
</script>

<style scoped>
* {
  color: white;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  position: relative;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  flex-wrap: wrap;
  
  position: absolute;
  z-index: 0;

  background-color: black;
  background-image: url('~@/assets/bg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 1;
}
.title-content {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
}

.home {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.device {
  position: relative;
  display: flex;
  width: 20%;
  height: 0;
  padding-bottom: 20%;
  margin: 3rem;
  cursor: pointer;
}

.device-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.device-title {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 10%;

  color: white;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
</style>